<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="12">
                                Tambah Jenis Tanaman
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <v-select :options="list_tanaman" label="tk_nama" v-model="kelompok_selected"></v-select>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Sub Kelompok Jenis Tanaman <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Sub Kelompok Jenis Tanaman" v-model="tks_nama">
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "AddKelompokJenisTanaman",
        components: {
        },
        data() {
            return {
                list_tanaman: null_array,
                kelompok_selected: '',
                tks_nama: '',
            }
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                var FormData = require('form-data');
                var data = new FormData();
                data.append('tanaman_kategori_id',this.kelompok_selected.id);
                data.append('tks_nama',this.tks_nama);
                var config = {
                  method: 'post',
                  url: this.apiLink+'api/crud/tanaman_kategori_sub',
                  headers: { 
                    'Authorization': 'Bearer '+this.access_token, 
                  },
                  data : data
                };
                axios(config)
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master sub kelompok tanaman",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({name: 'AllSubKelompokJenisTanaman'});
                            }
                        }).catch((error) => {
                            var error_res = error.response.data.meta;
                            if (error_res.code != 200) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error_res.message
                                });
                            }
                        });
                    }
                });
            },
        },
        created() {
            // Get Master Negara
            axios.get(this.apiLink + "api/master/tanaman", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tanaman = response.data;
                var res_tanaman_data = res_tanaman.data;
                if (res_tanaman.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tanaman.data.message
                    });
                }
                else{
                    this.list_tanaman = res_tanaman_data.master;
                    // console.log(res_tanaman_data.master);
                }
            });
        }
    };
</script>